<template>
  <v-app id="inspire">
    <!-- <section> -->
    <app-loader v-if="loader"></app-loader>

    <app-aside-bar-component :menu="menu" v-if="!verificacion_docs_proveedor"></app-aside-bar-component>
    <CartDrawerComponent v-if="hasAccessToCart"  />

    <header v-if="$vuetify.breakpoint.smAndDown" class="mb-3">
      <app-header-component />
    </header>
    <v-app-bar v-else app :elevation="0" :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'bgMinsal'" style="z-index: 200;">
      <app-header-component />
    </v-app-bar>

    <v-main class="app-main-wrapper bgMinsal">
      <!-- <section class="pa-2 pa-sm-4"> -->
      <section fluid class="pa-2 pa-sm-4" style="min-height: inherit; position: relative;" v-if="userInfo.user">
        <SpinnerComponent v-if="cargandoInstitucionesUsuario" spinning text="Cargando instituciones del usuario..." />
        <router-view v-else :key="$route.fullPath" />
      </section>
    </v-main>
    <!-- </section> -->
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { CartDrawerComponent } from '@/components/catalogoEnLinea/cart';
import AppLoader from "../components/AppLoaderComponent.vue";
import jwtDecode from "jwt-decode";
import ChatComponent from "@/components/ChatComponent";
import SpinnerComponent from "@/components/utils/SpinnerComponent.vue";

export default {
  name: "layout",
  components: {
    AppLoader,
    ChatComponent,
    CartDrawerComponent,
    SpinnerComponent,
  },
  data: () => ({
    cargandoInstitucionesUsuario: false,
  }),
  methods: {
    ...mapMutations(["setUserInfo"]),
    ...mapActions(["cargarInstitucionesUsuario"]),
    decodeToken(userToken) {
      const userDecode = jwtDecode(userToken);
      this.setUserInfo(userDecode);
    },
    ...mapMutations("agregarInsumo", ["setGetTour"]),
    async getTours() {
      const response = await this.services.TourService.getTour();
      this.tour = response.data;
      this.setGetTour(this.tour);
    },
    obtenerInstitucionesUsuario() {
      this.cargandoInstitucionesUsuario = true;
      this.cargarInstitucionesUsuario().finally(() => {
        this.cargandoInstitucionesUsuario = false;
      });
    },
  },
  computed: {
    ...mapState({
      title: "title",
      userInfo: "userInfo",
      token: "token",
      verificacion_docs_proveedor: "verificacion_docs_proveedor",
    }),
    ...mapState("utils", ["loader", "menu"]),
    ...mapState(["institucionesUsuario"]),
    hasAccessToCart() {
      return (this.haveRole("ROLE_CM_CATALOGO_ELECTRONICO_CARRITO") && !this.haveRole("ROLE_SUPER_ADMIN"));
    },
  },
  watch: {
    title: {
      handler: function (val) {
        if (val === '') val = 'COMPRASAL';
        document.title = val;
      },
      immediate: true,
    },
  },
  async created() {
    const userToken = this.token || localStorage.getItem("token");
    if (userToken) {
      this.decodeToken(userToken)
      this.getTours();
      if (this.institucionesUsuario.length === 0) this.obtenerInstitucionesUsuario();
    } else if (this.$route?.query?.code) {
      const tokenInfo = await this.services.auth.GetTokenDigitalIdentity(this.$route?.query?.code)
      this.decodeToken(tokenInfo?.data)
      this.setAuth(tokenInfo?.data)
      await this.$router.push('/')
    } else {
      this.$router.push("/identidad-digital").catch((e) => { });
    }
  },
};
</script>

<style lang="scss" scoped>
:deep(.app-main-wrapper .v-main__wrap) {
  position: relative;
  min-height: calc(100vh - 64px); 
}
</style>
